<template>
  <Select
    v-model="current"
    :placeholder="placeholder"
    :multiple="multiple"
    :filterable="filterable"
    :disabled="disabled"
    :size="size"
    clearable
    @on-change="change"
  >
    <Option
      v-for="(item, index) in devices"
      :key="index"
      :value="item.ieee_address"
      :label="item.friendly_name"
      >{{ item.friendly_name }}</Option
    >
  </Select>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    devices: {
      type: Array,
      default: () => [],
    },
    value: [Object, Array, Number,String,],
    multiple: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    filterable: { type: Boolean, default: false },
    size: { type: String, default: "default" },
    labelInValue: { type: Boolean, default: false },
    placeholder: { type: String, default: "请选择设备" },
  },
  data() {
    return {
      current: null,
    };
  },
  watch: {
    value() {
      this.setCurrent();
    },
  },
  methods: {
    change(val) {
      console.log(val);
      const { labelInValue } = this;
      this.$emit(
        "change",
        val
      );
      this.$emit('on-change', labelInValue
          ? this.devices.find((item) => item.ieee_address == val)
          : val)
    },
    setCurrent() {
      this.current = this.value;
    },
  },
};
</script>

<style></style>
