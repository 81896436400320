<template>
  <div class="layout-config h-100 p-10">
    <div class="title">
      <h3>{{ classroomName }}--智能场景新增</h3>
      <Button type="error" @click="goBack()" class="extra">返回</Button>
    </div>
    <Row :gutter="10" class="m-t-10">
      <Col span="14">
        <Form
          ref="form"
          :model="form"
          label-position="left"
          :label-width="80"
          label-colon
          class="m-t-10 w-80"
        >
          <FormItem label="场景名称">
            <Input
              v-model="form.sceneName"
              placeholder="请输入场景名称..."
              clearable
              class="w-50"
            />
          </FormItem>
          <Button
            type="dashed"
            long
            icon="md-add"
            @click="isShow = !isShow"
            class="w-80"
            >添加场景元素</Button
          >
          <FormItem label="设备列表" class="m-t-10" v-if="form.data.length">
            <!-- <h4 class="m-t-20">场景设备列表</h4> -->
            <Table
              ref="table"
              :columns="cols"
              :data="form.data"
              height="400"
              border
              class="m-t-10"
            />
          </FormItem>
          <div class="m-t-10">
            <Button @click="resetScene">重置场景</Button>
            <Button type="success" @click="saveData" class="m-l-5">保存</Button>
          </div>
        </Form>
      </Col>
      <Col span="10" class="m-t-30">
        <Form
          ref="sceneItem"
          :model="sceneItem"
          :rules="rules"
          label-position="left"
          :label-width="80"
          label-colon
          class="m-t-10 scene-item-warp"
          v-if="isShow"
        >
          <h4 class="text-c dashed p-10 m-b-10">场景设置</h4>
          <FormItem label="延迟秒数">
            <InputNumber v-model="sceneItem.sleep" :min="0" />
          </FormItem>
          <FormItem label="选择设备">
            <SelectDevice
              v-model="sceneItem.deviceId"
              :devices="devicesByType"
              label-in-value
              @on-change="getDeviceInfo"
              class="w-60"
            />
          </FormItem>
          <FormItem label="服务质量">
            <RadioGroup v-model="sceneItem.qos">
              <Radio :label="0">0(最多发一次)</Radio>
              <Radio :label="1">1(最少发一次)</Radio>
              <Radio :label="2">2(保证收一次)</Radio>
              <span class="m-l-10 f-10 c-red">*非最要通告请使用0</span>
            </RadioGroup>
          </FormItem>
          <FormItem label="设备设置">
            <Button type="success" @click="showModal(deviceSelected)">设置设备参数</Button>
          </FormItem>
          <div class="m-t-10 text-c p-10 dashed-t">
            <Button @click="resetForm" class="m-r-5">重置</Button>
            <Button type="info" @click="addSceneItem">添加</Button>
          </div>
        </Form>
      </Col>
    </Row>
    <GroupModal :modal="modal" @change="groupSetting" />
    <!-- <Drawer  v-model="isShow" position="right"></Drawer> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import store from "@/modules";
import SelectDevice from "./SelectDevice.vue";
import GroupModal from "../internetOfThing/modal.vue";
import { dict } from "@/config";
import { util } from "@/libs";
import MqttServer from "@/libs/mqttServer";
import { getScript } from "@/libs/api/smartClassroom";
export default {
  components: { SelectDevice, GroupModal },
  data() {
    const _this = this;
    return {
      typeShow: ["TS0503B", "TS0505B", "TS0601", "TS0011", "TS0013"],
      deviceSelected: {},
      form: {
        data: [],
      },
      sceneItem: {
        qos: 0,
      },
      rules: {
        deviceId: { required: true, message: "请先选择设备" },
        payload: { required: true, message: "请先设置设备参数" },
      },
      isShow: false,
      modal: {
        show: false,
        group: "",
      },
      cols: [
        {
          title: "序号",
          type: "index",
          key: "index",
          width: 70,
          align: "center",
        },
        { title: "设备名称", key: "name" },
        {
          title: "设备类型",
          render(h, w) {
            return h("span", dict.deviceType[w.row.modelId]);
          },
        },
        { title: "延迟秒数", key: "sleep", render: (h, w) => {
          return h('span', w.row.sleep ? w.row.sleep : '0')
        } },
        { title: "设备参数", key: "payload", ellipsis: true, tooltip: true },
        {
          title: "操作",
          render(h, w) {
            return h("Button", {
              props: { type: "error", size: "small", icon: "md-close" },
              on: {
                click() {
                  _this.form.data.splice(w.row._index, 1);
                },
              },
            });
          },
        },
      ],
      json: {
        sceneName: "xxx",
        data: [
          {
            sleep: 0,
            topic: "topic",
            payload: [],
            qos: 0,
          },
        ],
      },
    };
  },
  computed: {
    ...mapState("client", ["mqttServer", "client", "devices", "groupScenes"]),
    devicesByType() {
      let data = []
      if(this.devices.length) {
        data = this.devices.filter(item => this.typeShow.includes(item.model_id))
      }
      return data
    },
    classroomId() {
      return this.$route.query.classroomId || null;
    },
    classroomName() {
      return this.$route.query.classroomName || null;
    },
  },
  methods: {
    getDeviceInfo(info = {}) {
      if (info) {
        this.deviceSelected = info
        Object.assign(this.sceneItem, {
          name: info.friendly_name,
          topic: `zigbee/${this.classroomId}/${info.ieee_address}/set`,
          modelId: info.model_id,
        });
      }
    },
    // 打开弹框
    showModal(device) {
      if(!this.sceneItem.deviceId) {
        this.$Message.warning("请先选择设备！")
        return;
      }
      const types = {
        TS0503B: "1",
        TS0505B: "1",
        TS0601: "2",
        TS0011: "3",
        TS0013: "3",
      };
      Object.assign(this.modal, {
        show: true,
        title: device.friendly_name,
        group: device.ieee_address,
        type: types[device.model_id],
        typeShow: true,
        disabled: types[device.model_id] == '3' ? true : false,
        switchType: device.model_id == "TS0013" ? "2" : "1",
      });
    },
    groupSetting(res) {
      if (Object.keys(res).length == 0) {
        this.$Message.warning("请先选择配置参数！");
        return;
      }
      console.log("groupSetting res:", res);
      Object.assign(this.sceneItem, { payload: res });
      Object.assign(this.modal, { show: false });
    },
    resetForm() {
      if (this.$refs.sceneItem) {
        this.$refs.sceneItem.resetFields();
      }
      this.sceneItem = {
        qos: 0,
      };
    },
    addSceneItem() {
      if (!this.sceneItem.deviceId) {
        this.$Message.warning("请先选择设备!");
        return;
      }
      if (!this.sceneItem.payload) {
        this.$Message.warning("请先设置设备参数!");
        return;
      }
      this.form.data.push(util.deepcopy(this.sceneItem));
      this.isShow = !this.isShow;
      this.resetForm();
      console.log("addSceneItem this.form:", this.form, this.sceneItem);
    },
    resetScene() {
      if (this.$refs.form) this.$refs.form.resetFields();
      this.form = {
        data: [],
      };
    },
    saveData() {
      if (!this.form.sceneName) {
        this.$Message.warning("请输入场景名称!");
        return;
      }
      if (!this.form.data.length) {
        this.$Message.warning("请先添加场景元素!");
        return;
      }
      console.log(JSON.stringify({classroomId: this.classroomId, name: this.form.sceneName, script: JSON.stringify(this.form) }))
      // getScript({classroomId: this.classroomId, name: this.form.sceneName, script: JSON.stringify(this.form) }).then((res) => {
      //   if (res.code == "HA0200") {
      //     this.$Message.success("保存成功！");
      //     this.goBack();
      //   } else this.$Message.error(res.msg || "保存失败!");
      // });
    },
    goBack() {
      this.$router.push({
        path: "/config/intelligentControl/smartClassroom/smartScene",
        query: {
          classroomName: this.classroomName,
          classroomId: this.classroomId,
        },
      });
    },
    createClient() {
      const client = new MqttServer()
      store.commit('client/SET_MQTT_SERVER', client)
      store.dispatch('client/getClientMessage')
      this.client.subscribe(`zigbee/${this.classroomId}/#`);
      this.client.subscribe(`wifi/#`);
    },
  },
  mounted() {
    if (!this.mqttServer) {
      this.createClient();
    } else {
      console.log(this.groupScenes);
      const classroomId = localStorage.getItem("classroomId");
      console.log("classroomId:", classroomId, this.classroomId);
      if (classroomId != this.classroomId) {
        this.client.unsubscribe(`zigbee/${classroomId}/#`);
        store.commit("client/SET_CLASSROOM_ID", this.classroomId);
        this.client.subscribe(`zigbee/${this.classroomId}/#`);
      }
    }
  },
};
</script>

<style lang="less" scoped>
.layout-config {
  .title {
    position: relative;
    .extra {
      position: absolute;
      right: 10px;
      top: 0;
    }
  }
  .scene-item-warp {
    border: dashed 1px #dcdee2;
    border-radius: 10px;
    padding: 10px;
  }
  .list-title {
    height: 40px;
    line-height: 40px;
    border: solid 1px #dcdee2;
  }
  .list-border {
    border-left: solid 1px #dcdee2;
  }
  .dashed {
    border-bottom: dashed 1px #dcdee2;
  }
  .dashed-t {
    border-top: dashed 1px #dcdee2;
  }
  .list-item {
    height: 40px;
    line-height: 40px;
    border: dashed 1px #dcdee2;
    border-top: none;
    .list-border {
      border-left: dashed 1px #dcdee2;
    }
  }
}
</style>
